.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-layout-header {
  background: #fff;
  padding: 0px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-menu-horizontal>.ant-menu-item a {
  color: #fff;
}

/* 

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #CECE5D;
}
.ant-menu-item .anticon, .ant-menu-submenu-title-seleted .anticon {
  color: #CECE5D;
} */
.hidden-element .ant-form-item-control-input {
  display: none;
}

.flexItem {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid gray;
}

.flex {
  display: flex;
  justify-content: space-between;
  padding: 15px;

}

.divide {
  padding: 3px;
  margin: 3px;
}

.ck.ck-editor__editable_inline>:last-child {
  height: 180px
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

iframe {
  pointer-events: none;
}

.addBtn {
  border-radius: 0%;
  margin-right: 10px;
}

.headerMenuItems:hover {
  color: #1090F5 !important;
}